import { translate } from '@/languages/i18n'
import { REGEX_EMAIL } from './regex'
import { requiredIf, url, helpers } from '@vuelidate/validators';
const { withAsync, withParams } = helpers
import { debounce, isEmpty } from 'lodash';
import { customMaxLength } from '@/utils';
import { Ref } from 'vue';
import commonService from '@/services/common.service';

export const validateRequired = (value: string, key: string, title: string, params: any) => {
  if (!value) {
    return translate(key, title, params)
  }
  return null
}

export const minMaxString = (value: string, title: string, params: any) => {
  if (value.length < params[0] || value.length > params[1]) {
    return translate('minMaxString', title, params)
  }
  return null
}

export const minNum = (value: string, title: string, params: any) => {
  if (value.length < params[0]) {
    return translate('minNum', title, params)
  }
  return null
}

export const maxNum = (value: string, title: string, params: any) => {
  if (value.length > params[0]) {
    return translate('maxNum', title, params)
  }
  return null
}

export const validatePassword = (password: string, params: any) => {
  const required = validateRequired(password, 'required', '', [])
  const minMax = minMaxString(password, 'password', params)
  return required || minMax
}

export const validateEmail = (email: string, params: any) => {
  const required = validateRequired(email, 'required', '', [])
  const minMax = minMaxString(email, 'email', params)
  if (!REGEX_EMAIL.test(email) && email) {
    return translate('validateMail', 'mail', params)
  }
  return required || minMax
}

export const validateText = (value: string, title: string, params: any) => {
  const required = validateRequired(value, 'requiredInput', title, [])
  const minMax = minMaxString(value, title, params)
  return required || minMax
}

export const VALIDATOR_PAIR_REQUIRED = 'required'
export const VALIDATOR_MAX_LENGTH = 'maxLength'
export const VALIDATOR_VALID_URL = 'validUrl'
export const VALIDATOR_CONTAIN_NG_WORD = 'ngWord'
export const MAX_LENGTH_SITE_NAME = 20
export const MAX_LENGTH_SITE_URL = 4096

export const validateUrlRules = (siteName: string, siteUrl: string, formData: Ref) => {
  return {
    [siteName]: {
      [VALIDATOR_PAIR_REQUIRED]: requiredIf(!isEmpty(formData.value[siteUrl])),
      [VALIDATOR_MAX_LENGTH]: (value: string) => customMaxLength(value, MAX_LENGTH_SITE_NAME)
    },
    [siteUrl]: {
      [VALIDATOR_MAX_LENGTH]: (value: string) => customMaxLength(value, MAX_LENGTH_SITE_URL),
      [VALIDATOR_PAIR_REQUIRED]: requiredIf(!isEmpty(formData.value[siteName])),
      [VALIDATOR_VALID_URL]: url,
    },
  }
}